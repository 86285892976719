// @ts-nocheck
import { plugin } from './plugin';
import * as Plugin_0 from '../../app.tsx';
import * as Plugin_1 from '../plugin-initial-state/runtime';
import * as Plugin_2 from '../plugin-model/runtime';
import * as Plugin_3 from '/home/gitlab-runner/builds/y3bh9Xns/1/axinan/fe/b2c-id-web/src/.umi-production/plugin-helmet/runtime.ts';

  plugin.register({
    apply: Plugin_0,
    path: '../../app.tsx',
  });
  plugin.register({
    apply: Plugin_1,
    path: '../plugin-initial-state/runtime',
  });
  plugin.register({
    apply: Plugin_2,
    path: '../plugin-model/runtime',
  });
  plugin.register({
    apply: Plugin_3,
    path: '/home/gitlab-runner/builds/y3bh9Xns/1/axinan/fe/b2c-id-web/src/.umi-production/plugin-helmet/runtime.ts',
  });

export const __mfsu = 1;
